export const doubleDigits = (number: number): string =>
  number < 10 ? `0${number}` : `${number}`;

/**
 * we need to map old schedule model because
 * the week days and hours model should start from "00" instead of "01"
 * **/
export const oldScheduleMapper = (schedule: TSchedule): TSchedule => {
  const parseSch = (obj: TSchedule): TSchedule => {

    if(!obj['0']){
      return Object.entries(obj)
        .reduce((schedule, [key, value]) => {
          schedule[+key - 1] = value.map(hour => doubleDigits(+hour - 1 ));
          return schedule;
        }, {} as TSchedule);
    }

    const schedule: TSchedule = {...obj};
    delete schedule['7'];
    return schedule;
  }

  return !schedule['7'] ? {...schedule} : parseSch(schedule);
}

export const biddingStrategyMapToBackend = (data: IBiddingStrategyModel ): IBiddingStrategyBackendModel => {
  const {website_promotion} = data;

  if(website_promotion) {
    const option: IWebsitePromotionOption = {
      domain: website_promotion.optionDomain || '',
      condition: website_promotion.optionCondition || 'contains',
      value: website_promotion.optionValue || '',
    }
    
    delete website_promotion.optionValue;
    delete website_promotion.optionDomain;
    delete website_promotion.optionCondition;

    return { ...data, website_promotion: { ...website_promotion, option } } as IBiddingStrategyBackendModel;
  } else {
    return data as IBiddingStrategyBackendModel;
  }
}
export const biddingStrategyMapToFrontend = (data: IBiddingStrategyBackendModel): IBiddingStrategyModel => {
  const {website_promotion } = data;

  if(website_promotion ) {
    const { option } = website_promotion;
    if(!website_promotion.secondary_events){
      website_promotion.secondary_events = [];
    }
    return {
      ...data, website_promotion: {
        ...website_promotion,
        optionDomain: option?.domain || '',
        optionCondition: option?.condition || 'contains',
        optionValue: option?.value || '',
      }
    } as IBiddingStrategyModel;
  } else {
    return data as unknown as IBiddingStrategyModel
  }
}

export const initialAgeGroupData = (): string[] =>
  Object.keys([...new Array(48)]).map(item => String(Number(item) + 18));
